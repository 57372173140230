<template>
    <v-chart
    class="chartz"
    ref="chart"
    :manual-update="true"
    autoresize
    :loading="loading" />
</template>
  
<script>
import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { BarChart, LineChart } from 'echarts/charts'
import {
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    GridComponent,
} from 'echarts/components'
import { ref, defineComponent, toRefs, onMounted, reactive, watch } from 'vue'
import VChart from 'vue-echarts'

use([
    CanvasRenderer,
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    BarChart,
    LineChart,
    GridComponent,
])

export default defineComponent({
    name: 'EChartDataKokoh',
    components: {
        VChart,
    },
    props: {
        title: {
            default: '',
            type: String,
        },
        data: {
            default: () => [],
            type: Array,
        },
    },
    setup: props => {
        const state = reactive({
            loading: false,
            labels: [],
            data: [],
        })
        const chart = ref()
        const { data, loading } = toRefs(props)

        const option = ref({

            title: {
                text: props.title,
                left: 'center',
                textStyle: {
                    overflow: 'break',
                    fontSize: '1rem',
                },
            },
            textStyle: {
                overflow: 'break',
                fontSize: '0.8rem',
                width: 120,
            },
            grid: {
                containLabel: true,
                left: 30,
                right: 20,
                bottom: 20,
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow',
                },
            },
            legend: {
                type: 'scroll',
                bottom: 0,
                padding: [0, 0, 0, 0],
            },
            yAxis: [
                {
                    type: 'category',
                    axisLabel: {
                        show: false,
                    },
                    data: state.labels,
                    inverse: true,
                    name: 'Nama Area',
                    nameLocation: 'middle',
                },
            ],
            xAxis: [
                {
                    type: 'value',
                    name: 'Total Volume Area',
                    nameLocation: 'middle',
                    nameGap: 30,
                    axisLabel: {
                        formatter: function (a) {
                            const formatter = Intl.NumberFormat('id-ID', { notation: 'compact' })
                            return formatter.format(a)
                        },
                    },
                },
            ],
            series: [
                {
                    type: 'bar',
                    data: state.data,
                    showBackground: true,
                    label: {
                        show: true,
                        formatter: '{b}',
                        position: 'insideLeft',
                    },
                },
            ],
            media: [
                {
                    query: {
                        maxWidth: 300, // when container width is smaller than 300
                    },
                    option: {
                        title: {
                            text: props.title,
                            left: 'center',
                            textStyle: {
                                overflow: 'break',
                                width: 230,
                                fontSize: '.9rem',
                            },
                        },
                    },
                },
                {
                    query: {
                        minWidth: 300, // when container width is smaller than 300
                        maxWidth: 500, // when container width is smaller than 500
                    },
                    option: {
                        title: {
                            text: props.title,
                            left: 'center',
                            textStyle: {
                                overflow: 'break',
                                width: 300,
                            },
                        },
                    },
                },
                {
                    option: {
                        title: {
                            text: props.title,
                            left: 'center',
                            textStyle: {
                                overflow: 'none',
                            },
                        },
                    },
                },
            ],
        })

        const fetchData = () => {
            state.labels.splice(0, state.labels.length)
            state.data.splice(0, state.data.length)
            
            data.value.forEach(item => {
                state.labels.push(item.toko_area)
                state.data.push(item.id_toko_active || 0)
            })

            chart.value.setOption(option.value)
        }

        onMounted(() => {
            fetchData()
        })

        watch(data, () => {
            fetchData()
        })

        return { loading, chart }
    },
})
</script>
  
<style>
.chartz {
    height: 300px;
}
</style>
  